import { Card, Col, Row } from "antd";
import { MdGroupAdd, MdBarChart } from "react-icons/md";

function GraphicAssociatesTotal({ dataGrafics }: any) {
  return (
    <Card>
      <Row>
        <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <MdGroupAdd style={{ color: "#6f73d2", fontSize: 45 }} />
            <p
              style={{
                fontSize: "2rem",
                color: "#6f73d2",
                fontWeight: 500,
              }}
            >
              {dataGrafics.associatesTotal}
            </p>
            <p style={{ fontSize: "16px" }}>Asociadas y Asociados</p>
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <MdBarChart style={{ color: "#6f73d2", fontSize: 45 }} />
            <p
              style={{
                fontSize: "2rem",
                color: "#6f73d2",
                fontWeight: 500,
              }}
            >
              {dataGrafics.productsTotal}
            </p>
            <p style={{ fontSize: "16px" }}>Productos</p>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default GraphicAssociatesTotal;
