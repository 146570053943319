import { Card, Col, Row } from "antd";
import { IoMan, IoWoman, IoPerson } from "react-icons/io5";
import { CircularProgressWithLabel } from "../../../components";

function GraphicGender({ dataGrafics }: any) {
  const selectIconGender = (gender: any) => {
    switch (gender) {
      case "Hombres":
        return <IoMan style={{ color: "#3DD6D6", fontSize: 45 }} />;
      case "Mujeres":
        return <IoWoman style={{ color: "#3DD6D6", fontSize: 45 }} />;
      case "Otro":
        return <IoPerson style={{ color: "#3DD6D6", fontSize: 45 }} />;
      default:
        return <div></div>;
    }
  };
  return (
    <Card>
      <Row>
        {dataGrafics.gendersData &&
          Object.keys(dataGrafics.gendersData).map((x: any) => (
            <Col key={x} xs={24} sm={12} md={12} xl={12} xxl={8}>
              <CircularProgressWithLabel
                value={dataGrafics.gendersData[x].percentage}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                {selectIconGender(x)}
                <p>
                  {dataGrafics.gendersData[x].number} {x}
                </p>
              </div>
            </Col>
          ))}
      </Row>
    </Card>
  );
}

export default GraphicGender;
