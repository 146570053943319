import {
  Chart as ChartJS,
  ChartData,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  titleText: string;
  labels: string[];
  datasets: any;
  legendDisplay?: boolean;
  titleDisplay?: boolean;
  horizontal: boolean;
}

function BarChart({
  titleText = "",
  labels = [],
  datasets,
  legendDisplay = false,
  titleDisplay = true,
  horizontal = false,
}: IProps) {
  const [barChartOptions] = useState<any>({
    indexAxis: horizontal ? "y" : "x" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: legendDisplay,
        position: "right" as const,
      },
      title: {
        display: titleDisplay,
        text: titleText,
      },
    },
  });

  const [barChartData, setBarChartData] = useState<ChartData<"bar">>({
    labels: labels,
    datasets: datasets,
  });

  useEffect(() => {
    setBarChartData((prev: ChartData<"bar">) => ({ ...prev, datasets }));
  }, [datasets]);

  return <Bar options={barChartOptions} data={barChartData} />;
}

export default BarChart;
