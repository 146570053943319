import React, { useCallback, useEffect, useState } from "react";
import { severalStore } from "../../../stores";
import { Col, Form, Row, Spin } from "antd";
import {
  getDiagnostics,
  calculateQualificationByCategories,
} from "../../../servicesHTTP";
import { RadarChart, Select } from "../../../components";
import { getNameForm } from "../../../utilities";
import { graficRadarPalletteColors } from "../../../constants";

const datasetConstants = {
  pointBorderColor: "#fff",
  pointHoverBackgroundColor: "#fff",
  radius: 6,
  pointRadius: 6,
  pointBorderWidth: 3,
  pointHoverRadius: 6,
};

function RadarDiagnostic({ dataForms }: any) {
  const { setShowLoading, showLoading, formSelected }: any = severalStore();
  const [diagnostics, setDiagnostics] = useState<any[]>([]);
  const [diagnosticCompaSeleted, setDiagnosticCompaSeleted] = useState<any[]>(
    []
  );
  const [diagnosticSeleted, setDiagnosticSeleted] = useState<any>("0");
  const [dataQualification, setDataQualification] = useState<any>([]);

  const getDiagnosticsLocal = useCallback(
    async (id: any) => {
      setShowLoading(true);
      const data = await getDiagnostics(id);
      if (data.status === "ok") {
        setDiagnostics(data.data);
      }
      setShowLoading(false);
    },
    [setShowLoading]
  );

  useEffect(() => {
    (async () => {
      if (formSelected) {
        await getDiagnosticsLocal(formSelected.id);
        setDiagnosticCompaSeleted([]);
        setDiagnosticSeleted("0");
        setDataQualification([]);
      }
    })();
  }, [getDiagnosticsLocal, formSelected]);

  const getQualification = async (ids: any) => {
    const data = await calculateQualificationByCategories(ids);
    setDataQualification(data.data.result);
  };

  const handleChangeCompa = (e: any) => {
    const { value } = e.target;
    setDiagnosticCompaSeleted(value);
    setDiagnosticSeleted("0");
    getQualification(value);
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setDiagnosticSeleted(value);
    setDiagnosticCompaSeleted([]);
    getQualification([value]);
  };

  const getLabelRadar = (diagnostics: any, id_diagnostic: any) => {
    const diagnostic = diagnostics.find(
      (y: any) => String(y.id) === String(id_diagnostic)
    );
    if (diagnostic)
      return `${diagnostic.year} ${diagnostic?.period_type_object?.name} (${diagnostic?.period_object?.period})`;
    return "";
  };

  const generateRadarData = (data: any, diagnostics: any) => {
    const datasets = data.map((x: any, index: any) => ({
      ...datasetConstants,
      label: getLabelRadar(diagnostics, x.id_diagnostic),
      data: [
        x.organizationalQualification,
        x.economicActivityQualification,
        x.administrativeQualification,
        x.accountantQualification,
      ],
      ...graficRadarPalletteColors[index],
    }));

    return datasets;
  };

  const getStateQualification = (score: any) => {
    if (score >= 0 && score <= 2.5) return "Emprendimiento";
    if (score > 2.5 && score <= 4) return "Crecimiento";
    if (score > 4 && score <= 5) return "Consolidación";

    return "Definir";
  };

  const getColorQualification = (score: any) => {
    if (score >= 0 && score <= 2.5) return "#f96363";
    if (score > 2.5 && score <= 4) return "rgba(255, 236, 51, 0.8)";
    if (score > 4 && score <= 5) return "#a9fa99";

    return "";
  };

  if (!dataForms.fedeSelected && !dataForms.formSelected) {
    return (
      <div>
        Para visualizar el diagnóstico seleccionar una Federación o Forma
        Asociativa
      </div>
    );
  }

  return (
    <>
      <div className="mt-4" style={{ marginBottom: 20 }}>
        <h1 className="associative-forms-list-title">Gráficas Diagnóstico</h1>
        {getNameForm(formSelected) !== "" && (
          <span> ({getNameForm(formSelected)})</span>
        )}
      </div>
      <Spin spinning={showLoading} size="small" />
      <Row>
        <Col xs={24} md={11} xl={11} xxl={11}>
          <Form.Item label="Comparativo" layout="vertical">
            <Select
              mode="multiple"
              placeholder="Comparativo"
              option0={false}
              options={diagnostics.map((y: any) => ({
                value: String(y.id),
                label: `${y.year} ${y?.period_type_object?.name} (${y?.period_object?.period})`,
              }))}
              value={diagnosticCompaSeleted}
              onChange={handleChangeCompa}
            />
          </Form.Item>
          <RadarChart
            titleText="DIAGNÓSTICO"
            labels={[
              "ORGANIZATIVO",
              "ACTIVIDAD ECONÓMICA",
              "ADMINISTRATIVO",
              "CONTABLE",
            ]}
            datasets={generateRadarData(dataQualification, diagnostics)}
          />
        </Col>
        <Col xs={24} md={1} xl={1}></Col>
        <Col xs={24} md={11} xl={11} xxl={11}>
          <Form.Item label="Detalles Diagnóstico" layout="vertical">
            <Select
              placeholder="Detalles Diagnóstico"
              options={diagnostics.map((y: any) => ({
                value: String(y.id),
                label: `${y.year} ${y?.period_type_object?.name} (${y?.period_object?.period})`,
              }))}
              value={diagnosticSeleted}
              onChange={handleChange}
            />
          </Form.Item>
          {diagnosticSeleted !== "0" && dataQualification.length === 1 && (
            <div>
              <table className="table-qualification-diagnostic">
                <thead>
                  <tr>
                    <th>Ambito</th>
                    <th>Calificación</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      backgroundColor: getColorQualification(
                        dataQualification[0]?.organizationalQualification
                      ),
                    }}
                  >
                    <td>Organizativo</td>
                    <td>{dataQualification[0]?.organizationalQualification}</td>
                    <td>
                      {getStateQualification(
                        dataQualification[0]?.organizationalQualification
                      )}
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: getColorQualification(
                        dataQualification[0]?.economicActivityQualification
                      ),
                    }}
                  >
                    <td>Actividad Economica</td>
                    <td>
                      {dataQualification[0]?.economicActivityQualification}
                    </td>
                    <td>
                      {getStateQualification(
                        dataQualification[0]?.economicActivityQualification
                      )}
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: getColorQualification(
                        dataQualification[0]?.administrativeQualification
                      ),
                    }}
                  >
                    <td>Administrativo</td>
                    <td>{dataQualification[0]?.administrativeQualification}</td>
                    <td>
                      {getStateQualification(
                        dataQualification[0]?.administrativeQualification
                      )}
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: getColorQualification(
                        dataQualification[0]?.accountantQualification
                      ),
                    }}
                  >
                    <td>Contable</td>
                    <td>{dataQualification[0]?.accountantQualification}</td>
                    <td>
                      {getStateQualification(
                        dataQualification[0]?.accountantQualification
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <p>Convenciones</p>
          <table className="table-convention-qualification-diagnostic">
            <tbody>
              <tr style={{ backgroundColor: getColorQualification(1) }}>
                <td>De 0 a 2.5</td>
                <td>Emprendimiento</td>
              </tr>
              <tr style={{ backgroundColor: getColorQualification(3) }}>
                <td>De 2.6 a 4</td>
                <td>Crecimiento</td>
              </tr>
              <tr style={{ backgroundColor: getColorQualification(4.5) }}>
                <td>De 4.1 a 5</td>
                <td>Consolidación</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
}

export default RadarDiagnostic;
