import { useCallback, useEffect, useState } from "react";
import { Col, Divider, Form, Row, Spin } from "antd";
import { Page, Select } from "../../components";
import {
  getForms,
  getHomeGrafics,
  getHomeGraficsByIds,
} from "../../servicesHTTP";
import { severalStore, userStore } from "../../stores";
import { getNameForm } from "../../utilities";
import { URL_SERVER } from "../../config";
import logoDefault from "../../assets/Images/associative-form-logo.png";
import GraphicGender from "./graficsHome/GraphicGender";
import GraphicAssociatesTotal from "./graficsHome/GraphicAssociatesTotal";
import RadarDiagnostic from "./graficsHome/RadarDiagnostic";
import BarLevelSchooling from "./graficsHome/BarLevelSchooling";
import BarAssociativeSituation from "./graficsHome/BarAssociativeSituation";
import BarDisabilities from "./graficsHome/BarDisabilities";

function Home() {
  const {
    update: updateStore,
    setShowLoading,
    showLoading,
    federationsTypesIds,
    formSelected,
    getSeveralData,
  }: any = severalStore();
  const { user_type_id } = userStore();

  const [dataGrafics, setDataGrafics] = useState<any>({
    gendersData: {
      Hombres: {
        number: 0,
        percentage: 0,
      },
      Mujeres: {
        number: 0,
        percentage: 0,
      },
      Otro: {
        number: 0,
        percentage: 0,
      },
    },
    associatesTotal: 0,
    productsTotal: 0,
    associatesLevelSchooling: [],
    associativeSituation: [],
    disabilities: [],
  });
  const [dataForms, setDataForms] = useState<any>({
    forms: [],
    fedes: [],
    formSelected: null,
    fedeSelected: null,
  });

  useEffect(() => {
    (async () => {
      if (federationsTypesIds.length <= 0) await getSeveralData();
    })();
    // eslint-disable-next-line
  }, [federationsTypesIds.length]);

  const getFormsLocal = useCallback(async () => {
    setShowLoading(true);
    const data = await getForms();
    if (data.status === "ok") {
      const fede = data.data.filter((x: any) =>
        //@ts-ignore
        federationsTypesIds.includes(String(x.association_type_id))
      );

      const forms = data.data.filter(
        (x: any) =>
          //@ts-ignore
          !federationsTypesIds.includes(String(x.association_type_id))
      );

      if (forms.length > 0) {
        updateStore({ formSelected: { ...forms[0], numberLoad: 2 } });
        if (String(user_type_id) === "1") {
          setDataForms((prev: any) => ({
            ...prev,
            forms: forms,
            formSelected: { ...forms[0] },
          }));
        } else {
          setDataForms((prev: any) => ({ ...prev, forms: forms }));
        }
      }

      if (fede.length > 0) {
        updateStore({ formSelected: fede[0] });
        setDataForms((prev: any) => ({ ...prev, fedes: fede }));
      }
    }
    setShowLoading(false);
  }, [setShowLoading, federationsTypesIds, updateStore, user_type_id]);

  useEffect(() => {
    getFormsLocal();
  }, [getFormsLocal]);

  useEffect(() => {
    (async () => {
      const result = await getHomeGrafics();
      setDataGrafics(result);
    })();
  }, []);

  const getHomeGraficsChangeFede = () => {
    if (dataForms.fedeSelected) {
      let ids = [];
      const idFede = dataForms.fedeSelected.id;
      if (dataForms.fedeSelected.id === 100000) {
        ids = dataForms.forms
          .filter((x: any) => {
            if (x.belongs_to_federation.length <= 0) return true;
            return false;
          })
          .map((y: any) => y.id);
      } else {
        ids = dataForms.forms
          .filter((x: any) => {
            if (x.belongs_to_federation.length <= 0) return false;
            if (
              x.belongs_to_federation[0].id_federation ===
              dataForms?.fedeSelected?.id
            )
              return true;

            return false;
          })
          .map((y: any) => y.id);
      }
      ids.push(idFede);
      (async () => {
        setShowLoading(true);
        const result = await getHomeGraficsByIds(ids);
        setDataGrafics(result);
        setShowLoading(false);
      })();
    } else {
      (async () => {
        setShowLoading(true);
        const result = await getHomeGrafics();
        setDataGrafics(result);
        setShowLoading(false);
      })();
    }
  };

  useEffect(() => {
    getHomeGraficsChangeFede();
    // eslint-disable-next-line
  }, [dataForms.fedeSelected]);

  useEffect(() => {
    if (dataForms.formSelected) {
      const ids = [dataForms.formSelected.id];
      (async () => {
        setShowLoading(true);
        const result = await getHomeGraficsByIds(ids);
        setDataGrafics(result);
        setShowLoading(false);
      })();
    } else {
      getHomeGraficsChangeFede();
    }
    // eslint-disable-next-line
  }, [dataForms.formSelected]);

  const handleChangeFede = (value: any, fedes: any) => {
    if (value === "100000") {
      setDataForms((prev: any) => ({
        ...prev,
        fedeSelected: { id: 100000, nit: "0", name: "Sin Federación" },
        formSelected: null,
      }));
      updateStore({
        formSelected: { id: 100000, nit: "0", name: "Sin Federación" },
      });
      return;
    }
    setDataForms((prev: any) => ({
      ...prev,
      fedeSelected: fedes.find((x: any) => String(x.id) === value),
      formSelected: null,
    }));
    updateStore({
      formSelected: fedes.find((x: any) => String(x.id) === value),
    });
  };

  const handleChangeForm = (value: any, forms: any) => {
    setDataForms((prev: any) => ({
      ...prev,
      formSelected: forms.find((x: any) => String(x.id) === value),
    }));

    if (value === "0") {
      updateStore({ formSelected: dataForms.fedeSelected });
      return;
    }
    updateStore({
      formSelected: forms.find((x: any) => String(x.id) === value),
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "fedeSelected") {
      handleChangeFede(value, dataForms.fedes);
      return;
    }

    if (name === "formSelected") {
      handleChangeForm(value, dataForms.forms);
      return;
    }

    setDataForms((prev: any) => ({ ...prev, [name]: value }));
  };

  const selectFormsVisible = (forms: any, fedeSelected: any) => {
    if (!fedeSelected) return [];
    if (fedeSelected.id === 100000) {
      return forms
        .filter((x: any) => {
          if (x.belongs_to_federation.length <= 0) return true;

          return false;
        })
        .map((x: any) => ({
          value: String(x.id),
          label: x.name,
        }));
    }
    return forms
      .filter((x: any) => {
        if (x.belongs_to_federation.length <= 0) return false;
        if (x.belongs_to_federation[0].id_federation === fedeSelected?.id)
          return true;

        return false;
      })
      .map((x: any) => ({
        value: String(x.id),
        label: x.name,
      }));
  };

  return (
    <Page>
      <Row>
        <Col xs={24} md={5} xl={5}></Col>
        <Col xs={24} md={14} xl={14}>
          {dataForms.fedes.length <= 0 &&
            formSelected &&
            !federationsTypesIds.includes(
              String(formSelected.association_type_id)
            ) && (
              <Row style={{ marginBottom: 20 }}>
                <Col xs={24} md={12} xl={12} xxl={12}>
                  <div className="mt-4">
                    <h1 className="associative-forms-list-title">
                      {getNameForm(formSelected) !== "" && (
                        <span> {getNameForm(formSelected)}</span>
                      )}
                    </h1>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  xl={12}
                  xxl={12}
                  style={{ textAlign: "end" }}
                >
                  <img
                    className="img-fluid"
                    width="85"
                    height="90"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = logoDefault;
                    }}
                    src={`${URL_SERVER}/home_public/getLogoAssociativeForms/${
                      formSelected ? formSelected.nit : "0"
                    }`}
                    alt="Logo de la forma asociativa"
                  />
                </Col>
              </Row>
            )}
          {dataForms.fedes.length > 0 && (
            <Form layout="vertical" autoComplete="off">
              <Row style={{ marginBottom: 20 }}>
                <Col xs={24} md={18} xl={18} xxl={18}>
                  <Form.Item label="Federación">
                    <Select
                      style={{ width: "100%" }}
                      placeholder=""
                      labelOption0="Todas"
                      options={(() => {
                        if (
                          String(user_type_id) === "5" ||
                          String(user_type_id) === "3" ||
                          String(user_type_id) === "2"
                        ) {
                          return [
                            {
                              value: "100000",
                              label: "Sin Federación",
                            },
                            ...dataForms.fedes.map((x: any) => ({
                              value: String(x.id),
                              label: x.name,
                            })),
                          ];
                        }
                        return [
                          ...dataForms.fedes.map((x: any) => ({
                            value: String(x.id),
                            label: x.name,
                          })),
                        ];
                      })()}
                      name="fedeSelected"
                      value={
                        dataForms?.fedeSelected?.id
                          ? String(dataForms?.fedeSelected?.id)
                          : "0"
                      }
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6} xl={6} xxl={6} style={{ textAlign: "end" }}>
                  <img
                    className="img-fluid"
                    width="85"
                    height="90"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = logoDefault;
                    }}
                    src={`${URL_SERVER}/home_public/getLogoAssociativeForms/${
                      dataForms?.fedeSelected
                        ? dataForms?.fedeSelected.nit
                        : "0"
                    }`}
                    alt="Logo Federación"
                  />
                </Col>
              </Row>

              <Row style={{ marginBottom: 20 }}>
                <Col xs={24} md={18} xl={18} xxl={18}>
                  <Form.Item label="Forma asociativa">
                    <Select
                      style={{ width: "100%" }}
                      placeholder=""
                      labelOption0="Todas"
                      options={selectFormsVisible(
                        dataForms.forms,
                        dataForms?.fedeSelected
                      )}
                      name="formSelected"
                      value={
                        dataForms?.formSelected?.id
                          ? String(dataForms?.formSelected?.id)
                          : "0"
                      }
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6} xl={6} xxl={6} style={{ textAlign: "end" }}>
                  <img
                    className="img-fluid"
                    width="85"
                    height="90"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = logoDefault;
                    }}
                    src={`${URL_SERVER}/home_public/getLogoAssociativeForms/${
                      dataForms?.formSelected
                        ? dataForms?.formSelected.nit
                        : "0"
                    }`}
                    alt="Logo de la forma asociativa"
                  />
                </Col>
              </Row>
            </Form>
          )}
          <Divider />
          <div className="mt-4" style={{ marginBottom: 20 }}>
            <h1 className="associative-forms-list-title">
              Gráficas Caracterización
            </h1>
            {(dataForms.fedeSelected || dataForms.formSelected) && (
              <span> ({getNameForm(formSelected)})</span>
            )}
          </div>
          <Spin spinning={showLoading} size="small" />
          <Row>
            <Col xs={24} md={11} xl={11}>
              {dataGrafics.gendersData && (
                <GraphicGender dataGrafics={dataGrafics} />
              )}

              {dataGrafics.associatesLevelSchooling &&
                dataGrafics.associatesLevelSchooling.length > 0 && (
                  <BarLevelSchooling dataGrafics={dataGrafics} />
                )}

              {dataGrafics.disabilities &&
                dataGrafics.disabilities.length > 0 && (
                  <BarDisabilities dataGrafics={dataGrafics} />
                )}
            </Col>
            <Col xs={24} md={1} xl={1}></Col>
            <Col xs={24} md={11} xl={11}>
              <GraphicAssociatesTotal dataGrafics={dataGrafics} />

              {dataGrafics.associativeSituation &&
                dataGrafics.associativeSituation.length > 0 && (
                  <BarAssociativeSituation dataGrafics={dataGrafics} />
                )}
            </Col>
          </Row>
          <Divider />
          <RadarDiagnostic dataForms={dataForms} />
        </Col>
        <Col xs={24} md={5} xl={5}></Col>
      </Row>
    </Page>
  );
}

export default Home;
