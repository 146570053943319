import { Card } from "antd";
import { BarChart } from "../../../components";
import { graficRadarPalletteColors } from "../../../constants";

function BarAssociativeSituation({ dataGrafics }: any) {
  const generateAssociativeSituationData = (data: any) => {
    if (!data) return [];
    const datasets = [
      {
        label: "",
        data: data.map((x: any) => x.cantidad),
        ...graficRadarPalletteColors[0],
        backgroundColor: "#3dd6d6",
      },
    ];

    return datasets;
  };
  return (
    <Card style={{ marginTop: 15 }}>
      <BarChart
        horizontal={true}
        titleText="Estado Asociadas y Asociados"
        labels={dataGrafics.associativeSituation.map((x: any) => x.name)}
        datasets={generateAssociativeSituationData(
          dataGrafics.associativeSituation
        )}
      />
    </Card>
  );
}

export default BarAssociativeSituation;
