import { Card } from "antd";
import { BarChart } from "../../../components";
import { graficRadarPalletteColors } from "../../../constants";

function BarLevelSchooling({ dataGrafics }: any) {
  const generateAssociatesLevelSchoolingData = (data: any) => {
    if (!data) return [];
    const datasets = [
      {
        label: "",
        data: data.map((x: any) => x.cantidad),
        ...graficRadarPalletteColors[0],
        backgroundColor: "#3dd6d6",
      },
    ];

    return datasets;
  };
  return (
    <Card style={{ marginTop: 15 }}>
      <BarChart
        horizontal={true}
        titleText="Nivel Escolaridad"
        labels={dataGrafics.associatesLevelSchooling.map((x: any) => x.name)}
        datasets={generateAssociatesLevelSchoolingData(
          dataGrafics.associatesLevelSchooling
        )}
      />
    </Card>
  );
}

export default BarLevelSchooling;
